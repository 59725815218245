import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {axiosBaseQuery} from '../axiosBaseQuery';
import {API_URL} from '../../constants';

export const contractSlice = createApi({
  baseQuery: axiosBaseQuery({baseUrl: API_URL}),
  reducerPath: 'contract',
  tagTypes: ['Customers', 'Students', 'ContractRelation'],
  endpoints: (build) => ({
    getDocument: build.query({
      query: ({id, documentTypeId, format}) => ({
        url: `user/invoice/${id}/${documentTypeId}/${format}`,
        method: 'GET',
        responseType: 'blob',
      }),
    }),
    getDocumentList: build.query({
      query: (contractId) => ({
        url: `user/contracts/${contractId}/documents`,
        method: 'GET',
      }),
    }),
    getContractList: build.query({
      query: () => ({
        url: 'user/contracts',
        method: 'GET',
      }),
      transformResponse: (response) => response?.data,
    }),
    getContractInfo: build.query({
      query: (id) => ({
        url: `user/contracts/${id}`,
        method: 'GET',
      }),
    }),
    getStudentList: build.query({
      query: ({id}) => ({
        url: `user/contracts/${id}/students`,
        method: 'GET',
      }),
      transformResponse: (response) => response?.data,
      providesTags: ['Students'],
    }),
    updateStudentInfo: build.mutation({
      query: ({id, data}) => ({
        url: `user/contracts/students/change-query/${id}`,
        method: 'PATCH',
        data,
      }),
    }),
    getCustomers: build.query({
      query: () => ({
        url: `user/customers`,
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),
    getCustomer: build.query({
      query: ({id}) => ({
        url: `user/customers/${id}`,
        method: 'GET',
      }),
      providesTags: ['Customer'],
      transformResponse: (response) => response.data,
    }),
    updateCustomerInfo: build.mutation({
      query: ({id, data}) => ({
        url: `user/customers/change-query/${id}`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: ['Customer'],
    }),
    getBonusesHistory: build.query({
      query: () => ({
        url: 'user/bonuses-history',
        method: 'GET',
      }),
    }),
    addNewStudent: build.mutation({
      query: ({contractId, data}) => ({
        url: `user/contracts/${contractId}/students/add-query`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Students'],
    }),
    createNewContract: build.mutation({
      query: ({data}) => ({
        url: `user/contracts`,
        method: 'POST',
        data,
      }),
    }),
    createNewCustomer: build.mutation({
      query: (data) => ({
        url: `user/customers`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Customers'],
    }),
    payContract: build.query({
      query: (contractId) => ({
        url: `user/contractPayment/${contractId}`,
        method: 'GET',
      }),
    }),
    shareBonuses: build.mutation({
      query: (data) => ({
        url: `user/bonusesTransferToEmail`,
        method: 'POST',
        data,
      }),
    }),
    getContractsRelations: build.query({
      query: (contractId) => ({
        url: `user/contracts/relations/check/${contractId}`,
        method: 'GET',
      }),
      providesTags: ['ContractRelation'],
    }),
    getAvailableContractsRelations: build.query({
      query: (contractId) => ({
        url: `user/contracts/relations/checkFull/${contractId}`,
        method: 'GET',
      }),
      providesTags: (result, err, arg) => [{type: 'ContractRelation', id: arg}],
    }),
    deleteContractFromRelation: build.mutation({
      query: (contractId) => ({
        url: `user/contracts/relations/${contractId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ContractRelation'],
    }),
    addContractToRelation: build.mutation({
      query: ({contractId, relationContractId = null}) => ({
        url: `user/contracts/relations/${contractId}`,
        method: 'PATCH',
        data: {
          relationContractId,
        },
      }),
      invalidatesTags: ['ContractRelation'],
    }),
    deleteRelation: build.mutation({
      query: (relationId) => ({
        url: `user/contracts/relations/full/${relationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ContractRelation'],
    }),
  }),
});

export const {
  useGetDocumentQuery,
  useGetDocumentListQuery,
  useGetContractListQuery,
  useGetStudentListQuery,
  useGetContractInfoQuery,
  useUpdateStudentInfoMutation,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useUpdateCustomerInfoMutation,
  useGetBonusesHistoryQuery,
  useAddNewStudentMutation,
  useGetCustomersQuery,
  useCreateNewCustomerMutation,
  usePayContractQuery,
  useCreateNewContractMutation,
  useShareBonusesMutation,
  useGetContractsRelationsQuery,
  useGetAvailableContractsRelationsQuery,
  useDeleteContractFromRelationMutation,
  useAddContractToRelationMutation,
  useDeleteRelationMutation,
} = contractSlice;

export const {getContractList, getCustomer, getStudentList, getDocumentList, getContractInfo} = contractSlice.endpoints;
