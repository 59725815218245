import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {axiosBaseQuery} from '../axiosBaseQuery';
import {API_URL} from '../../constants';

export const studentSlice = createApi({
  baseQuery: axiosBaseQuery({baseUrl: API_URL}),
  reducerPath: 'student',
  tagTypes: ['Diploma', 'Answer'],
  endpoints: (build) => ({
    showPaymentButton: build.query({
      query: () => ({
        url: 'student/payment-required',
        method: 'GET',
      }),
    }),
    getTrainings: build.query({
      query: () => ({
        url: 'student/student-trainings',
        method: 'GET',
      }),
      providesTags: ['Diploma', 'Answer'],
    }),
    getTraining: build.query({
      query: ({programId}) => ({
        url: `student/student-trainings/${programId}`,
        method: 'GET',
      }),
    }),
    getLectureList: build.query({
      query: (programId) => ({
        url: `student/student-trainings/${programId}/edu-materials/lectures`,
        method: 'GET',
      }),
    }),
    getPresentationList: build.query({
      query: (programId) => ({
        url: `student/student-trainings/${programId}/edu-materials/presentations`,
        method: 'GET',
      }),
    }),
    getVideoList: build.query({
      query: (programId) => ({
        url: `student/student-trainings/${programId}/edu-materials/videos`,
        method: 'GET',
      }),
    }),
    getLecture: build.query({
      query: ({programId, lectureId}) => ({
        url: `student/student-trainings/${programId}/edu-materials/lectures/${lectureId}`,
        method: 'GET',
        contentType: 'application/pdf',
      }),
    }),
    getPresentation: build.query({
      query: ({programId, presentationId}) => ({
        url: `student/student-trainings/${programId}/edu-materials/presentations/${presentationId}`,
        method: 'GET',
      }),
    }),
    getVideo: build.query({
      query: ({programId, videoId}) => ({
        url: `student/student-trainings/${programId}/edu-materials/videos/${videoId}`,
        method: 'GET',
      }),
    }),
    getDativeFullName: build.query({
      query: (name) => ({
        url: 'student/getDatName',
        method: 'GET',
        params: {name},
      }),
    }),
    sendPersonalInfo: build.mutation({
      query: (data) => ({
        url: 'student/confirm-personal-data',
        method: 'POST',
        data,
      }),
    }),
    sendDiploma: build.mutation({
      query: (data) => ({
        url: 'student/diploms',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Diploma'],
    }),
    askTechSupport: build.mutation({
      query: (data) => ({
        url: 'student/send-curator-email',
        method: 'POST',
        data,
      }),
    }),
    getTest: build.query({
      query: (id) => ({
        url: `student/test/test/${id}`,
        method: 'GET',
      }),
    }),
    getExam: build.query({
      query: (id) => ({
        url: `student/test/exam/${id}`,
        method: 'GET',
      }),
    }),
    checkStudentAnswer: build.mutation({
      query: (data) => ({
        url: 'student/test/check-student-answer',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Answer'],
    }),
    finishTest: build.query({
      query: (studentTraining) => ({
        url: `student/test/test/${studentTraining}/finish`,
        method: 'GET',
      }),
    }),
    getExamResult: build.query({
      query: (trainingProgramsPhase) => ({
        url: `student/test/get-phase-result/${trainingProgramsPhase}`,
        method: 'GET',
      }),
    }),
    getStudentContract: build.query({
      query: () => ({
        url: 'student/get-contract-info',
        method: 'GET',
      }),
    }),
    sendEisotAttempt: build.query({
      query: (body) => ({
        url: 'student/sendEisotTime',
        method: 'POST',
        data: body,
      }),
    }),
  }),
});

export const {
  useShowPaymentButtonQuery,
  useGetTrainingsQuery,
  useGetTrainingQuery,
  useGetLectureListQuery,
  useGetPresentationListQuery,
  useGetVideoListQuery,
  useGetLectureQuery,
  useGetPresentationQuery,
  useGetVideoQuery,
  useGetDativeFullNameQuery,
  useSendPersonalInfoMutation,
  useSendDiplomaMutation,
  useAskTechSupportMutation,
  useGetTestQuery,
  useGetExamQuery,
  useCheckStudentAnswerMutation,
  useFinishTestQuery,
  useGetExamResultQuery,
  useLazySendEisotAttemptQuery,
  useGetStudentContractQuery,
} = studentSlice;

export const {getTraining} = studentSlice.endpoints;
