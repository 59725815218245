import {combineReducers} from 'redux';

import {studentSlice} from '../api/slices/student';
import {authSlice} from '../api/slices/auth';
import {contractSlice} from '../api/slices/user';
import {publicSlice} from '../api/slices/public';

const rootReducer = combineReducers({
  [studentSlice.reducerPath]: studentSlice.reducer,
  [authSlice.reducerPath]: authSlice.reducer,
  [contractSlice.reducerPath]: contractSlice.reducer,
  [publicSlice.reducerPath]: publicSlice.reducer,
});

export default rootReducer;
