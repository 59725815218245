import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from '../axiosBaseQuery';
import {API_URL} from '../../constants';

export const publicSlice = createApi({
  baseQuery: axiosBaseQuery({baseUrl: API_URL}),
  reducerPath: 'trainings',
  endpoints: (build) => ({
    getProgramTypes: build.query({
      query: () => ({
        url: 'public/trainingProgramTypes',
        method: 'GET',
      }),
    }),
    getProgramsByType: build.query({
      query: ({id}) => ({
        url: `public/trainingPrograms/${id}`,
        method: 'GET',
      }),
    }),
    checkExpressDelivery: build.mutation({
      query: (data) => ({
        url: `public/checkExpressDelivery`,
        method: 'POST',
        data,
      }),
    }),
    suggestPrompt: build.mutation({
      query: ({type, query}) => ({
        url: 'public/dadata/suggest',
        method: 'POST',
        data: {
          type,
          query,
        },
      }),
    }),
    getCompanySettings: build.query({
      query: () => ({
        url: 'public/company-settings',
        method: 'GET',
      }),
      transformResponse: (response) => response?.data,
    }),
    calcContractCost: build.mutation({
      query: (data) => ({
        url: `public/calc-contract-cost`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useGetProgramTypesQuery,
  useGetProgramsByTypeQuery,
  useCheckExpressDeliveryMutation,
  useSuggestPromptMutation,
  useGetCompanySettingsQuery,
  useCalcContractCostMutation,
} = publicSlice;

export const {getCompanySettings} = publicSlice.endpoints;
