import axios from 'axios';

export const axiosBaseQuery =
  ({baseUrl}) =>
  async ({url, method, data, params, contentType = 'application/json', responseType}) => {
    const accessToken = localStorage.getItem('accessToken');

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        responseType,
        data,
        params,
        headers: {
          'Content-Type': contentType,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {data: result.data};
    } catch (err) {
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
