import {configureStore} from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import {studentSlice} from '../api/slices/student';
import {authSlice} from '../api/slices/auth';
import {contractSlice} from '../api/slices/user';
import {publicSlice} from '../api/slices/public';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      studentSlice.middleware,
      authSlice.middleware,
      contractSlice.middleware,
      publicSlice.middleware
    ),
});

export default store;
