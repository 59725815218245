import classes from './styles.module.scss';

const Loader = () => {
  return (
    <div data-testid="loader" className={classes.loader}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Loader;
