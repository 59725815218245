import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {axiosBaseQuery} from '../axiosBaseQuery';
import {API_URL} from '../../constants';

export const authSlice = createApi({
  baseQuery: axiosBaseQuery({baseUrl: API_URL}),
  reducerPath: 'auth',
  endpoints: (build) => ({
    getUserInfo: build.query({
      query: () => ({
        url: 'auth/profile',
        method: 'GET',
      }),
    }),
    loginAsStudent: build.mutation({
      query: (data) => ({
        url: 'auth/login-as-student',
        method: 'POST',
        data,
      }),
    }),
    loginAsCustomer: build.mutation({
      query: () => ({
        url: 'auth/login-back-under-user',
        method: 'POST',
      }),
    }),
    loginAsAdmin: build.mutation({
      query: () => ({
        url: 'auth/login-back-under-admin',
        method: 'POST',
      }),
    }),
  }),
});

export const {useGetUserInfoQuery, useLoginAsStudentMutation, useLoginAsCustomerMutation, useLoginAsAdminMutation} =
  authSlice;

export const {getUserInfo} = authSlice.endpoints;
